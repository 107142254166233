import React from "react";
import { Link } from "react-router-dom";
import HomeCard from "./HomeCard";
interface Props {
  products: Array<{
    brand_name: string;
    price: number;
    images: string[];
    strength: string;
    _id: string;
  }>;
  title: string;
  isVerified?: boolean;
}
function HomeRow({ title, products, isVerified }: Props) {
  return (
    <div className="content-section__class-con">
      <div className="content-section__class-con__title">
        {" "}
        <h3>{title}</h3>{" "}
        <Link to={"/all?category=" + title.toLowerCase()}>
          <small>see more</small>
        </Link>
      </div>
      <div className="content-section__class-con__class-row card">
        {products.map((product) => (
          <HomeCard key={product._id} {...product} isVerified={isVerified} />
        ))}
      </div>
    </div>
  );
}

export default HomeRow;
