import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import { useNavigate } from "react-router-dom";

function Profile() {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  return (
    <div className="profile-con">
      <h4>Account Details</h4>
      <div className="profile-con__account">
        <div className="profile-con__account__summary">
          <div className="summary-block">
            <p>{(user?.firstname ?? "") + " " + (user?.lastname ?? "")}</p>
          </div>
          <div className="summary-block">
            <p>{user?.business_name}</p>
          </div>
          <div className="summary-block">
            <p>{user?.email}</p>
          </div>
          <div className="summary-block">
            <p>{user?.phone_number}</p>
          </div>
          {user?.is_verified ? (
            <div className="summary-block verified">Verified Account</div>
          ) : (
            <div className="summary-block unverified">
              Awaiting Verification
            </div>
          )}
        </div>
        <div className="profile-con__account__settings">
          <div className="profile-con__account__settings__block">
            <h4>Address</h4>
            <p>{user?.default_address?.street}</p>
            <p>{user?.default_address?.state}</p>
            <button onClick={() => navigate("/account/address_book")}>
              Address Book
            </button>
          </div>
          <div className="profile-con__account__settings__block">
            <button>Change Password</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
