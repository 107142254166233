import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
//import createStore from "./customScore"
import { reducers as reducer } from "../controllers/reducers";

export default function store() {
  return configureStore({
    reducer,
    middleware: [...getDefaultMiddleware()],
  });
}
