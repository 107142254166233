import React, { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import "../../resources/styles/home.scss";
import NavBar from "./components/NavBar";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../controllers/reducers/products";
import SideMenu from "./components/SideMenu";
import { RootState } from "../..";
import { setUser } from "../../controllers/reducers/user";
import { api } from "../../controllers/api";
type ContextType = { showMenu: Boolean };
function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const handleShowMenu = () => {
    if (!showMenu) {
      setShowMenu(true);
      document.body.classList.add("no-scroll");
    } else handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setShowMenu(false);
    document.body.classList.remove("no-scroll");
  };
  const handleClick = (e: React.SyntheticEvent) => {
    let target = e.target as HTMLElement;
    if (!target.closest(".slide-in") && !target.closest(".hamburger")) {
      handleCloseMenu();
    }
    if (!target.closest(".nav-bar__one__account-con__account-options")) {
      setShowProfileMenu(false);
    }
  };

  const verifyUserToken = async () => {
    try {
      let req = await fetch(api + "/verify-user", {
        headers: { Authorization: "Bearer " + user?.token },
      });
      if (req.ok) {
        let res = await req.json();
        dispatch(setUser(res));
      } else navigate("/auth/login");
    } catch (error) {}
  };

  useEffect(() => {
    verifyUserToken();
    dispatch(fetchProducts());
  }, []);
  const handleMenu = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (!user) {
      navigate("/auth/login", { state: location.pathname });
      return;
    }
    setShowProfileMenu(!showProfileMenu);
  };
  return (
    <div onClick={handleClick} className="home-con">
      <NavBar
        menu={{ showMenu, handleShowMenu }}
        profileMenu={{ handleMenu, showMenu: showProfileMenu }}
      />
      <SideMenu handleMenu={{ showMenu, handleCloseMenu }} />

      <Outlet context={{ showMenu }} />
    </div>
  );
}

export default Home;
export const useShowMenu = () => useOutletContext<ContextType>();
