import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../..";
import { api } from "../../../controllers/api";
import OrderCard from "./OrderCard";
import { IOrder } from "./Orders";

function OrderDetail() {
  const params = useParams();
  const { user } = useSelector((state: RootState) => state);
  const [order, setOrder] = useState<IOrder>();
  const getOrder = async () => {
    try {
      let req = await fetch(api + "/order/" + params.id, {
        headers: { Authorization: "Bearer " + user?.token },
      });
      if (req.status === 200) {
        let order = await req.json();
        setOrder(order);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getOrder();
  });
  return (
    <div className="order-detail-con">
      <div className="order-detail-con__header">
        <h3>Order Number: {order?.orderNumber}</h3>
        <p>{order?.orders.length} Item(s)</p>
        <p>Date Ordered: {moment(order?.createdAt).format("DD-MMM-YYYY")}</p>
        <p>Total: &#8358;{order?.total.toLocaleString()}</p>
      </div>
      <p>ITEMS ORDERED</p>
      <div className="order-detail-con__items">
        {order?.orders.map((item) => (
          <OrderCard
            key={item.image}
            order={item}
            orderNumber={order.orderNumber}
          />
        ))}
      </div>
    </div>
  );
}

export default OrderDetail;
