import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../index";
import { api } from "../../../controllers/api";
import FilterBox from "./FilterBox";
import ProductCard from "./ProductCard";
import {
  startLoading,
  stopLoading,
} from "../../../controllers/reducers/loader";
interface IGetDrugs {
  title: string;
  value: string;
  page: number;
  sort: string;
  firstLoad?: boolean;
  brand?: string;
  formulation?: string;
  category?: string;
}
function CategoryPage() {
  let { search } = useLocation();
  const { user } = useSelector((state: RootState) => state);
  const [title, setTitle] = useState({ title: "", type: "" });
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    brand: "",
    category: "",
    formulation: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [filterObject, setFilterObject] = useState({
    brands: [],
    categories: [],
    formulations: [],
  });
  const [query, setQuery] = useState({
    page: 0,
    sort: "brand_name:1",
  });
  let [drugs, setDrugs] = useState<Array<any>>();
  const handleGetDrugs = async ({
    title,
    value,
    page,
    sort,
    firstLoad,
    brand,
    formulation,
    category,
  }: IGetDrugs) => {
    dispatch(startLoading());
    try {
      let req = await fetch(
        `${api}/search?${title}=${value}&page=${page}&sort=${sort}&firstLoad=${firstLoad}&brands=${brand}&categories=${category}&formulations=${formulation}&id=` +
          (user ? user._id : "")
      );
      if (req.ok) {
        let res = await req.json();
        console.log(res);

        setDrugs(res.products);

        setFilterObject(res);
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(stopLoading());
  };
  const handleGetQuery = (search: string) => {
    let key = search.slice(1, search.indexOf("="));
    let value = search.slice(search.indexOf("=") + 1);
    setTitle({ title: value.replace(/%20/g, " "), type: key });
    return { title: key, value };
  };
  useEffect(() => {
    handleGetDrugs({ ...handleGetQuery(search), ...query, firstLoad: true });
    //handleGetQuery(search);
  }, [search, query]);
  const handleSort = (e: ChangeEvent) => {
    let select = e.target as HTMLSelectElement;
    let sort = "";
    switch (select.value) {
      case "A-Z":
        sort = "brand_name:1";
        handleGetDrugs({ ...handleGetQuery(search), ...query, sort });
        setQuery((state) => {
          return { ...state, sort };
        });
        break;

      case "Z-A":
        sort = "brand_name:-1";
        handleGetDrugs({ ...handleGetQuery(search), ...query, sort });
        setQuery((state) => {
          return { ...state, sort };
        });
        break;

      case "low_to_high":
        sort = "price:1";
        handleGetDrugs({ ...handleGetQuery(search), ...query, sort });
        setQuery((state) => {
          return { ...state, sort };
        });
        break;

      case "high_to_low":
        sort = "price:-1";
        handleGetDrugs({ ...handleGetQuery(search), ...query, sort });
        setQuery((state) => {
          return { ...state, sort };
        });
        break;
      case "expiry_date_asc":
        sort = "expiry_date:1";
        handleGetDrugs({ ...handleGetQuery(search), ...query, sort });
        setQuery((state) => {
          return { ...state, sort };
        });
        break;
      case "expiry_date_dsc":
        sort = "expiry_date:-1";
        handleGetDrugs({ ...handleGetQuery(search), ...query, sort });
        setQuery((state) => {
          return { ...state, sort };
        });
        break;
    }
  };
  const handelFilter = (key: string, value: string | undefined) => {
    setTimeout(() => {
      handleGetDrugs({
        ...handleGetQuery(search),
        ...query,
        firstLoad: true,
        [key]: value,
      });
    }, 500);
    setFilters((state) => {
      return { ...state, [key]: value };
    });
    setShowFilter(false);
  };

  return (
    <div className="list-page">
      <div className="filter-con">
        <p className="filter-title">Filters</p>
        {title.type !== "brand" && filterObject.brands.length > 0 && (
          <FilterBox
            setFilter={handelFilter}
            name="brand"
            title="Brands"
            filters={filterObject.brands}
          />
        )}
        {title.type !== "category" && filterObject.categories.length > 0 && (
          <FilterBox
            name="category"
            setFilter={handelFilter}
            title="Categories"
            filters={filterObject.categories}
          />
        )}
        {title.type !== "formulation" && filterObject.categories.length > 0 && (
          <FilterBox
            name="formulation"
            setFilter={handelFilter}
            title="Formulations"
            filters={filterObject.formulations}
          />
        )}
      </div>
      <div className="products-con">
        <div className="products-con__header">
          <p>{title.title}</p>
          <select onChange={handleSort} name="sort" id="sort">
            <option value="A-Z">A - Z</option>
            <option value="Z-A">Z - A</option>
            <option value="low_to_high">Price: Low to high</option>
            <option value="high_to_low">Price: Hight to low</option>
            <option value="expiry_date_asc">Expiry: Asc</option>
            <option value="expiry_date_dsc">Expiry: Dsc</option>
          </select>
        </div>
        <div className="products-con__cards-con">
          {drugs?.map((drug) => (
            <ProductCard
              key={drug._id}
              id={drug._id}
              name={drug.brand_name}
              image={drug.images[0]}
              {...drug}
            />
          ))}
        </div>
        <div className="mobile-filter">
          <div
            style={{ visibility: showFilter ? "visible" : "hidden" }}
            className="mobile-filter__filter-con"
          >
            <div className="mobile-filter__filter-con__header">
              <p>Add Filter(s)</p>
            </div>
            {title.type !== "brand" && filterObject.brands.length > 0 && (
              <FilterBox
                setFilter={handelFilter}
                title="Brands"
                name="brand"
                filters={filterObject.brands}
              />
            )}
            {title.type !== "category" &&
              filterObject.categories.length > 0 && (
                <FilterBox
                  setFilter={handelFilter}
                  title="Categories"
                  name="category"
                  filters={filterObject.categories}
                />
              )}
            {title.type !== "formulation" &&
              filterObject.categories.length > 0 && (
                <FilterBox
                  setFilter={handelFilter}
                  title="Formulations"
                  name="formulation"
                  filters={filterObject.categories}
                />
              )}
            <p onClick={() => setShowFilter(false)} className="filter-back">
              Back
            </p>
          </div>

          <div
            onClick={() => setShowFilter(true)}
            className="mobile-filter__filter"
          >
            {" "}
            <p>Filter</p>{" "}
          </div>
          <div className="mobile-filter__sort">
            <select onChange={handleSort} name="sort" id="sort">
              <option value="A-Z">A - Z</option>
              <option value="Z-A">Z - A</option>
              <option value="low_to_high">Price: Low to high</option>
              <option value="high_to_low">Price: Hight to low</option>
              <option value="expiry_date_asc">Expiry: Asc</option>
              <option value="expiry_date_dsc">Expiry: Dsc</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryPage;
