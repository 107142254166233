import React, { FormEvent, useEffect, useRef, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../resources/images/plus.svg";
import { ReactComponent as CancelIcon } from "../../../resources/images/cancel.svg";
import { ReactComponent as TickIcon } from "../../../resources/images/tick.svg";
import { api } from "../../../controllers/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../..";
import { setUser } from "../../../controllers/reducers/user";
import {
  startLoading,
  stopLoading,
} from "../../../controllers/reducers/loader";
interface IState {
  state: string;
  lgas: { lga: string; delivery_fee: number }[];
}

function AddressBook() {
  const [showForm, setShowForm] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  let dispatch = useDispatch();
  const [states, setStates] = useState<IState[]>([]);
  const [state, setState] = useState<IState>();
  const user = useSelector((state: RootState) => state.user);

  const getStates = async () => {
    dispatch(startLoading());
    try {
      let req = await fetch(api + "/states");
      if (req.ok) {
        let res = await req.json();
        setStates(res);
      }
    } catch (error) {}
    dispatch(stopLoading());
  };

  const deleteAddress = async (id: string) => {
    dispatch(startLoading());
    try {
      let req = await fetch(api + "/customer-address/" + id, {
        method: "DELETE",
        headers: { Authorization: "Bearer " + user?.token },
      });
      if (req.ok) {
        let res = await req.json();
        dispatch(setUser(res));
      }
    } catch (error) {
      alert("unable to delete address");
    }
    dispatch(stopLoading());
  };
  const setDefaultAddress = async (id: string) => {
    dispatch(startLoading());
    try {
      let req = await fetch(api + "/customer-address/" + id, {
        method: "PATCH",
        headers: { Authorization: "Bearer " + user?.token },
      });
      if (req.ok) {
        let res = await req.json();
        dispatch(setUser(res));
      }
    } catch (error) {
      alert("unable to delete address");
    }
    dispatch(stopLoading());
  };

  const handleAddressSubmit = async (e: FormEvent) => {
    dispatch(startLoading());
    try {
      e.preventDefault();
      let body = new FormData(e.target as HTMLFormElement);
      let req = await fetch(api + "/customer-address", {
        method: "POST",
        body,
        headers: { Authorization: "Bearer " + user?.token },
      });
      if (req.ok) {
        let res = await req.json();
        dispatch(setUser(res));
        setShowForm(false);
      }
    } catch (error) {
      alert("unable to add address");
    }
    dispatch(stopLoading());
  };
  useEffect(() => {
    getStates();
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowForm(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <div ref={ref} className="address_book_con">
      <div className="address_book_con__header">
        <h3>Address Book</h3>
        <PlusIcon title="Add new Address" onClick={() => setShowForm(true)} />
      </div>
      <div className="addresses">
        {user?.addresses.map((address) => (
          <AddressCard
            address={address}
            deleteAddress={deleteAddress}
            key={address.street}
            setDefaultAddress={setDefaultAddress}
          />
        ))}
      </div>

      {showForm && (
        <AddAddressForm
          states={states}
          setShowForm={setShowForm}
          setState={setState}
          handleAddressSubmit={handleAddressSubmit}
          state={state}
        />
      )}
    </div>
  );
}

export default AddressBook;
interface IFormProps {
  handleAddressSubmit: (e: FormEvent) => Promise<void>;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  states: IState[];
  state: IState | undefined;
  setState: React.Dispatch<React.SetStateAction<IState | undefined>>;
}
const AddAddressForm = ({
  handleAddressSubmit,
  setShowForm,
  states,
  setState,
  state,
}: IFormProps) => (
  <>
    {" "}
    <form onSubmit={handleAddressSubmit} className="address-form">
      <div className="address-form__header">
        <h3>Add New Address</h3>
        <CancelIcon title="cancel" onClick={() => setShowForm(false)} />
      </div>
      <div className="address-form__input-con">
        <label htmlFor="">State</label>
        <select
          onChange={(e) => {
            let selectedState = e.target.value;
            let state = states.find((state) => state.state === selectedState);
            setState(state);
          }}
          name="state"
        >
          <option value="">Select State</option>
          {states.map((state) => (
            <option value={state.state}>{state.state}</option>
          ))}
        </select>
      </div>
      <div className="address-form__input-con">
        <label htmlFor="">LGA</label>
        <select name="lga">
          {state?.lgas.map((lga) => (
            <option value={lga.lga}>{lga.lga}</option>
          ))}
        </select>
      </div>
      <div className="address-form__input-con">
        <label htmlFor="">Street</label>
        <input name="street" type="text" />
      </div>
      <div className="address-form__input-con">
        <label htmlFor="">Landmark</label>
        <input name="landmark" type="text" />
      </div>
      <div className="address-form__input-con default">
        <label htmlFor="">Set As Default Address</label>
        <input name="is_default" value={"true"} type="checkbox" />
      </div>
      <button>Save</button>
    </form>
  </>
);

interface IAddressCard {
  address: {
    state: string;
    lga: string;
    street: string;
    landmarks: string;
    is_default: boolean;
    _id: string;
  };
  deleteAddress: (id: string) => void;
  setDefaultAddress: (id: string) => void;
}
const AddressCard = ({
  address,
  deleteAddress,
  setDefaultAddress,
}: IAddressCard) => {
  return (
    <>
      {" "}
      <div className="address-card">
        <div className="address-card__header">
          <p> {address.street}</p>
          {address.is_default && <TickIcon />}
        </div>
        <p>{address.lga}</p>
        <p>{address.state}</p>
        <div className="button-con">
          {!address.is_default && (
            <button
              onClick={() => setDefaultAddress(address._id)}
              className="default"
            >
              Set As Default
            </button>
          )}
          <button className="edit">Edit</button>
          <button onClick={() => deleteAddress(address._id)} className="delete">
            Delete
          </button>
        </div>
      </div>
    </>
  );
};
