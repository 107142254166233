import React, { useState } from "react";
import { api } from "../../../controllers/api";

function Image(props: { images: string[]; brand_name: string }) {
  const [loading, setLoading] = useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <div className="_con">
      <img
        style={{ visibility: loading ? "hidden" : "visible" }}
        onLoad={handleImageLoad}
        src={api + "/image/" + props?.images[0]}
        alt={props.brand_name}
      />

      {loading && <div className="img-loader"></div>}
    </div>
  );
}

export default Image;
