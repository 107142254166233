import React from "react";
import { Outlet } from "react-router-dom";
import "../../resources/styles/auth.scss";
function Authorization() {
  return (
    <div className="auth-con">
      <div className="auth-con__header">
        <p>EasyMeds</p>
      </div>
      <div className="auth-con__outlet">
        <Outlet />
      </div>
    </div>
  );
}

export default Authorization;
