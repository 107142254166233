import React, { ChangeEvent, useState } from "react";
interface IProps {
  title: string;
  name: string;
  filters: { name: string; count: number; _id: string }[];
  setFilter: (key: string, value: string) => void;
}
function FilterBox({ title, filters, setFilter, name }: IProps) {
  const [value, setValue] = useState("");

  const handleChange = (e: ChangeEvent) => {
    let newValue = (e.target as HTMLInputElement).value;
    if (newValue === value) {
      setValue("");
      setFilter(name, "");
    } else {
      setFilter(name, newValue);
      setValue(newValue);
    }
  };
  return (
    <div className="filter-box">
      <p className="filter-box__title">{title}</p>
      <div className="filter-box__checkbox-con">
        {filters.map(({ name, _id, count }) => (
          <label key={_id} className="filter-box__checkbox-con__label">
            <input
              checked={value === _id}
              onChange={handleChange}
              type="checkbox"
              value={_id}
            />
            <span className="checkmark"></span>
            {_id} ({count})
          </label>
        ))}
      </div>
    </div>
  );
}

export default FilterBox;
