import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { api } from "../../controllers/api";
import { startLoading, stopLoading } from "../../controllers/reducers/loader";
import "./checkout.scss";
import Summary from "./components/Summary";
import { useNavigate } from "react-router-dom";
function CheckOut() {
  const navigate = useNavigate();
  const { user, cart } = useSelector((state: RootState) => state);
  const defaultAddress = user?.addresses.find(
    (address) => address.is_default === true
  );
  const dispatch = useDispatch();
  const handleToPayment = async () => {
    dispatch(startLoading());

    try {
      let options = {
        method: "POST",
        body: JSON.stringify({ email: user?.email, amount: cart.cart_total }),
        headers: {
          Authorization: "Bearer " + user?.token,
          "Content-type": "application/json",
        },
      };
      let req = await fetch(api + "/payment", options);
      if (req.ok) {
        let response = await req.json();
        window.location.href = response.url;
      }
    } catch (error) {}

    dispatch(stopLoading());
  };
  return (
    <div className="checkout-con">
      <div className="checkout-con__navbar">
        <h4>EasyMeds</h4>
      </div>
      <div className="checkout-con__body">
        <div className="checkout-con__body__actions">
          <div className="address-header">
            <h4>Address</h4>
            <small onClick={() => navigate("/account/address_book")}>
              change
            </small>
          </div>

          <div className="address-card">
            <p className="address-card__title">
              {user?.business_name}
              <span className="address-card__address">
                {defaultAddress?.street ?? ""}, {defaultAddress?.state ?? ""}
              </span>
            </p>
          </div>
        </div>
        <Summary />
      </div>
      <button onClick={handleToPayment}>Proceed To Payment</button>
    </div>
  );
}

export default CheckOut;
