import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../..";
import { api } from "../../../controllers/api";
import Pagination from "../../../controllers/Pagination";
import Image from "../../home/components/Image";
export interface IOrder {
  _id: string;
  orderNumber: string;
  orders: IOrderItem[];
  returned: { type: Boolean; default: false };
  returndate: Date;

  customerId: string;
  total: number;
  createdAt: string;
}
export interface IOrderItem {
  generic_name: string;
  brand_name: string;
  image: string;
  price: number;
  quantity: number;
  name: string;
  status: string;
  merchantId: string;
  merchantEmail: string;
  merchantPhone: string;
}
export enum OrderStatus {
  Processing = "PROCESSING",
  Shipped = "SHIPPED",
  Delivered = "DELIVERED",
  Cancelled = "CANCELLED",
  Returned = "RETURNED",
  Rejected = "REJECTED",
}
function Orders() {
  let PageSize = 20;
  const [mode, setMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useSelector((state: RootState) => state);
  const [orders, setOrders] = useState<{
    orderData: IOrder[];
    count: number;
  }>();
  const navigate = useNavigate();
  const handleMode = async (mode: boolean) => {
    setMode(!mode);
  };

  const getOrders = async (page: number) => {
    try {
      let request = await fetch(api + `/orders/${page}/${PageSize}`, {
        headers: { Authorization: "Bearer " + user?.token },
      });
      if (request.status === 404) navigate("/");
      if (request.status === 200) {
        setOrders(await request.json());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getOrders(page - 1);
  };
  const handleToOrder = (id: string) => {
    return () => navigate("/account/orders/" + id);
  };
  useEffect(() => {
    getOrders(0);
  }, []);
  return (
    <div className="orders-con">
      <h3>My Orders</h3>
      <div className="orders-con__header">
        <div className="orders-con__header__title">
          <p onClick={() => handleMode(true)}>Open Orders ({orders?.count})</p>
          <p className="active" onClick={() => handleMode(false)}>
            Closed Orders (0)
          </p>
        </div>
        <div
          className={
            "orders-con__header__indicator " + (mode ? "left" : "right")
          }
        ></div>
      </div>

      <div className="orders-con__body">
        <>
          {" "}
          {orders?.orderData.map((order) => (
            <div className="orders-con__body__card">
              <div className="orders-con__body__card__body">
                <p className="orders-con__body__card__body__title">
                  Order Number - {order.orderNumber}
                </p>
                <p className="orders-con__body__card__body__sub">
                  Total: &#8358;{order.total.toLocaleString()}
                </p>
                <div className="orders-con__body__card__body__item-preview-con">
                  {order.orders.map((item) => (
                    <div className="orders-con__body__card__body__item-preview-con__image-con">
                      <Image images={[item.image]} brand_name={item.name} />
                    </div>
                  ))}
                </div>
                <p>
                  Date Ordered: {moment(order.createdAt).format("DD-MM-YY")}
                </p>
                <p
                  onClick={handleToOrder(order._id)}
                  className="orders-con__body__card__body__status"
                >
                  View More
                </p>
              </div>
            </div>
          ))}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={orders?.count ? orders.count : 0}
            pageSize={PageSize}
            onPageChange={(page) => handlePageChange(page)}
          />
        </>
      </div>
    </div>
  );
}

export default Orders;
