import React, { ChangeEvent, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as HeartFilled } from "../../resources/images/heart_filled.svg";
import { ReactComponent as HeartEmpty } from "../../resources/images/heart_empty.svg";
import { ReactComponent as PlusIcon } from "../../resources/images/plus.svg";
import { ReactComponent as MinusIcon } from "../../resources/images/minus.svg";
import "./description.scss";
import { api } from "../../controllers/api";
import moment from "moment";
import Image from "../home/components/Image";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { startLoading, stopLoading } from "../../controllers/reducers/loader";
import {
  addToCart,
  deleteFromCart,
  updateCart,
} from "../../controllers/reducers/cart";

interface Product {
  brand_name?: String;
  generic_name: string;
  price?: number;
  rating?: number;
  strength?: string;
  pack_size?: string;
  images: string[];
  expiry_date?: String;
  merchant_id?: String;
  company?: String;
  description?: String;
}
export enum option {
  plus,
  minus,
  neutral,
}
let timer = 0;
function Description() {
  const [product, setProduct] = useState({
    images: [""],
    brand_name: "",
    generic_name: "",
    strength: "",
    pack_size: 0,
    company: "",
    price: 0,
    expiry_date: "",
    description: "",
    merchant_id: "",
  });
  const { user, cart } = useSelector((state: RootState) => state);
  const [liked, setLiked] = useState(false);
  const [quantity, setQuantity] = useState(0);
  let cartIndex = 0;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const checkCart = (id: string, cart: any[]): boolean => {
    let exist = false;
    for (let x = 0; x < cart.length; x++) {
      if (cart[x].product._id === id) {
        exist = true;
        cartIndex = x;
        setQuantity(cart[x].quantity);
        setInCart(true);
        break;
      }
    }
    return exist;
  };
  const { id } = useParams();
  const [inCart, setInCart] = useState(false);
  const handleLike = () => {
    setLiked((state) => (state ? false : true));
  };
  const handleFetchProduct = async (id: string) => {
    dispatch(startLoading());
    try {
      let request = await fetch(`${api}/product/${id}`);
      if (request.ok) {
        let product = await request.json();
        console.log(product);
        checkCart(id, cart.cart);
        setProduct(product);
      }
    } catch (error) {
      alert("product fetch failed");
    }
    dispatch(stopLoading());
  };

  const handleAddToCart = async () => {
    if (!user) {
      navigate("/auth/login", { state: location.pathname });
      return;
    } else if (!checkCart(id!!, cart.cart)) {
      dispatch(startLoading());

      try {
        let req = await fetch(`${api + "/cart"}`, {
          body: JSON.stringify({
            product: {
              _id: id,
              name: product.brand_name,
              price: product.price ?? 0,
              image: product.images[0],
              expiry_date: product.expiry_date,
              merchant_id: product.merchant_id,
              company: product.company,
            },
            product_id: id,
            price: product.price ?? 0,
            quantity: 1,
            date_added: new Date().toISOString(),
          }),
          method: "POST",
          headers: {
            AUthorization: "Bearer " + user?.token,
            "Content-type": "application/json",
          },
        });
        if (req.ok) {
          let newCart = await req.json();
          checkCart(id!!, newCart.cart);
          setInCart(true);
          dispatch(addToCart(newCart));
        }
        dispatch(stopLoading());
      } catch (error) {
        alert("something went wrong");
        dispatch(stopLoading());
      }
    }
  };
  useEffect(() => {
    handleFetchProduct(id!!);
  }, []);
  const handleCartQuantity = (arrg: Number) => {
    return async (e: ChangeEvent<HTMLInputElement> | any) => {
      clearTimeout(timer);

      let newQuantity = quantity;
      switch (arrg) {
        case option.plus:
          newQuantity++;
          //  setQuantity((state) => state + 1 ?? 0);
          break;
        case option.minus:
          if (quantity > 1) {
            newQuantity--;
          } else handleDeleteFromCart();
          break;
        default:
          newQuantity = parseInt(
            e.target.value === "" ? 0 : e.target.value,
            10
          );
          e.target.value = "";
          //setQuantity(newQuantity - 0);
          break;
      }
      let cartProduct = {
        product_id: id,
        product: cart.cart[cartIndex].product,
        quantity: newQuantity,
      };
      setQuantity(newQuantity);
      timer = window.setTimeout(() => {
        handleUpdateCart(cartProduct, newQuantity);
      }, 500);
    };
  };
  const handleUpdateCart = async (cartProduct: any, newQuantity: number) => {
    dispatch(startLoading());
    try {
      let req = await fetch(api + "/cart", {
        method: "PATCH",
        body: JSON.stringify(cartProduct),
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "Content-type": "application/json",
        },
      });
      if (req.ok) {
        setQuantity(newQuantity);
        dispatch(updateCart(cartProduct));
      }
      dispatch(stopLoading());
    } catch (error) {
      console.log(error);
      alert("Update of product failed");
      dispatch(stopLoading());
    }
    dispatch(stopLoading());
  };
  const handleDeleteFromCart = async () => {
    dispatch(startLoading());
    try {
      let req = await fetch(`${api}/cart/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${user!!.token}` },
      });
      if (req.ok) {
        let res = await req.json();
        setInCart(false);
        dispatch(deleteFromCart({ id }));
        dispatch(stopLoading());
      }
      dispatch(stopLoading());
    } catch (error) {
      console.log(error);
      dispatch(stopLoading());
    }
  };

  return (
    <div className="description-con">
      <section className="description-con__section-one">
        <div className="carousel-con">
          <Carousel
            showThumbs={false}
            className="carousel-con"
            dynamicHeight={true}
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            autoPlay={true}
          >
            {product?.images.map((image) => (
              <div key={image}>
                <Image brand_name={product.brand_name} images={[image]} />
              </div>
            ))}
          </Carousel>
        </div>{" "}
        <div className="description-con__section-one__info">
          <div className="description-con__section-one__info__header">
            <h2>
              {product?.brand_name ??
                "Augmentin Artemether Lumenfantrine Combination"}
            </h2>{" "}
            {liked ? (
              <HeartFilled title="Remove from wishlist" onClick={handleLike} />
            ) : (
              <HeartEmpty title="Add to wishlist" onClick={handleLike} />
            )}
          </div>{" "}
          {/* <Link to={"/"} className="merchant">
            {product?.merchant_id ?? "Okon and sons"}
          </Link> */}
          <div className="detail-con">
            <p>Brand Name</p>
            <p>{product?.brand_name ?? "Augmentin"}</p>
          </div>
          <div className="detail-con">
            <p>Generic Name</p>
            <p>{product?.generic_name ?? "Augmentin"}</p>
          </div>
          <div className="detail-con">
            <p>Pack size</p>
            <p>{product?.pack_size ?? "10x30"}</p>
          </div>
          <div className="detail-con">
            <p>Strength</p>
            <p>{product?.strength ?? "62mg"}</p>
          </div>
          <div className="detail-con">
            <p>Company</p>
            <p>{product?.company ?? "GSK"}</p>
          </div>
          <div className="detail-con">
            <p>Expiration Date</p>
            <p>{moment(product?.expiry_date).format("MMM/YY")}</p>
          </div>{" "}
          <div className="detail-con">
            <Link to={"/merchant/" + product.merchant_id}>Merchant</Link>
          </div>{" "}
          <p className="price">
            &#8358;{product?.price?.toLocaleString() ?? "0.00"}
          </p>
          <div className="detail-input-con">
            {inCart ? (
              <div className="detail-input-con__input-con">
                <MinusIcon onClick={handleCartQuantity(option.minus)} />
                <input
                  onChange={handleCartQuantity(option.neutral)}
                  type="number"
                  value={quantity}
                  placeholder="0"
                />
                <PlusIcon onClick={handleCartQuantity(option.plus)} />
              </div>
            ) : (
              <button onClick={handleAddToCart}>Add to Cart</button>
            )}
          </div>
        </div>
      </section>
      <section className="description-con__section-two">
        <h2>Product Description</h2>
        <p>
          {product?.description ??
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati deleniti culpa, incidunt quia illo nobis voluptate voluptas harum odio, assumenda, ut dolores commodi dolorum labore ex in temporibus perspiciatis quisquam?"}
        </p>
      </section>
      <section className="description-con__section-three">
        <h2>Reviews & Ratings</h2>
        <p>Coming Soon...</p>
      </section>

      <div className="mobile-add-to-cart">
        {inCart ? (
          <div className="detail-input-con__input-con">
            <MinusIcon onClick={handleCartQuantity(option.minus)} />
            <input
              onChange={handleCartQuantity(option.neutral)}
              type="number"
              value={quantity}
              placeholder="0"
            />
            <PlusIcon onClick={handleCartQuantity(option.plus)} />
          </div>
        ) : (
          <button onClick={handleAddToCart}>Add to Cart</button>
        )}
      </div>
    </div>
  );
}

export default Description;
