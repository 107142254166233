import React from "react";
import { api } from "../../../controllers/api";
import Image from "../../home/components/Image";
import { IOrder, IOrderItem, OrderStatus } from "./Orders";
interface IProp {
  orderNumber: string;
  order: IOrderItem;
}
function OrderCard({ orderNumber, order }: IProp) {
  return (
    <div className="order-detail-con__items__card">
      <div className="order-detail-con__items__card__image-con">
        <Image brand_name={order.name} images={[order.image]} />
      </div>
      <div className="order-detail-con__items__card__body">
        <p className="order-detail-con__items__card__body__title">
          {order.name}
        </p>
        <p className="order-detail-con__items__card__body__sub">
          {order.quantity.toLocaleString()} units
        </p>
        <p className="order-detail-con__items__card__body__sub">
          &#8358;{order.price}
        </p>
        <p className="order-detail-con__items__card__body__sub">
          Merchant: {order.merchantId}
        </p>
        <p
          className={
            "order-detail-con__items__card__body__status " +
            (order.status === OrderStatus.Shipped
              ? "shipped"
              : order.status === OrderStatus.Delivered
              ? "delivered"
              : order.status === OrderStatus.Returned
              ? "returned"
              : order.status === OrderStatus.Cancelled
              ? "cancelled"
              : "Processing")
          }
        >
          {order.status === OrderStatus.Shipped
            ? "Shipped"
            : order.status === OrderStatus.Delivered
            ? "Delivered"
            : order.status === OrderStatus.Returned
            ? "Returned"
            : order.status === OrderStatus.Cancelled
            ? "Cancelled"
            : "Processing"}
        </p>
      </div>
    </div>
  );
}

export default OrderCard;
