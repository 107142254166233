import React from "react";
import "./account.scss";
import { ReactComponent as PersonIcon } from "../../resources/images/person.svg";
import OrderImage from "../../resources/images/order.png";
import { ReactComponent as WishlistIcon } from "../../resources/images/heart_empty.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
function Account() {
  let path = useLocation().pathname;
  const navigate = useNavigate();
  console.log(path);
  const handleClick = (path: String) => {
    return () => navigate("/account/" + path);
  };
  return (
    <div className="account-con">
      <div className="account-con__account-menu">
        <div
          onClick={handleClick("profile")}
          className={
            "account-con__account-menu__option " +
            (path.includes("/account/profile") ? "active" : "")
          }
        >
          <PersonIcon />
          <p>Profile</p>
        </div>
        <div
          onClick={handleClick("orders")}
          className={
            "account-con__account-menu__option " +
            (path.includes("/account/orders") ? "active" : "")
          }
        >
          <img src={OrderImage} alt="Orders" /> <p>Orders</p>
        </div>
        <div
          onClick={handleClick("wishlist")}
          className={
            "account-con__account-menu__option heart " +
            (path.includes("/account/wishlist") ? "active" : "")
          }
        >
          <WishlistIcon /> <p>Wishlist</p>
        </div>
      </div>
      <div className="account-con__account-body">
        <Outlet />
      </div>
    </div>
  );
}

export default Account;
