import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../controllers/api";
import { setCart } from "../../../controllers/reducers/cart";
import { setUser } from "../../../controllers/reducers/user";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasError, setHasError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    setDisabled(true);
    e.preventDefault();
    let form = new FormData(e.currentTarget);
    try {
      let req = await fetch(api + "/auth-user", { method: "POST", body: form });
      if (req.ok) {
        let res = await req.json();
        dispatch(setUser(res));
        dispatch(setCart({ cart_count: res.cart_count, cart: [] }));
        if (location.state) navigate(`${location.state}`);
        else navigate("/");
      } else if (req.status === 403) {
        setHasError(true);
      }
    } catch (error) {
      console.log(error);
    }
    setDisabled(false);
  };

  return (
    <div className="login-con">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className={"login-con__input-con " + (hasError ? "error" : "")}>
          <label>Email</label>
          <input
            onFocus={() => {
              setHasError(false);
            }}
            type="text"
            name="identity"
          />
        </div>
        <div className={"login-con__input-con " + (hasError ? "error" : "")}>
          <label>Password</label>
          <input
            onFocus={() => {
              setHasError(false);
            }}
            type="password"
            name="password"
          />
          <small className={hasError ? "error" : ""}>
            Invalid email or password
          </small>
        </div>
        <button disabled={disabled} type="submit" className="btn-submit">
          {disabled ? "Please wait.." : "Login"}
        </button>
      </form>
      <small>
        Create Account <Link to={"/auth/signup"}>here</Link>
      </small>
    </div>
  );
}

export default Login;
