import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../index";
import { api } from "../../controllers/api";
import { IState, setCart } from "../../controllers/reducers/cart";
import "./cart.scss";

import CartCard from "./components/CartCard";
import { startLoading, stopLoading } from "../../controllers/reducers/loader";
import { useNavigate } from "react-router-dom";
function Cart() {
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const { user, cart } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const handleHeight = () => {
    const navBar = document.querySelector(".nav-bar");
    if (navBar) {
      setHeight(window.innerHeight - navBar.clientHeight);
    }
  };
  const getCart = async () => {
    dispatch(startLoading());
    try {
      let req = await fetch(`${api}/cart`, {
        headers: { Authorization: `Bearer ${user!!.token}` },
      });
      if (req.ok) {
        let total = 0;
        let res: IState = await req.json();
        dispatch(setCart(res));

        setTotal(total);
      }
      dispatch(stopLoading());
    } catch (error) {
      console.log(error);
      dispatch(stopLoading());
    }
  };

  const handleToCheckOut = () => navigate("/checkout");
  useEffect(() => {
    handleHeight();
    getCart();
  }, []);
  window.onresize = () => handleHeight();
  return (
    <div style={{ height: height + "px" }} className="cart-con">
      <div className="cart-con__cart-cards">
        <h2>Shopping Cart</h2>
        {cart?.cart?.map((product) => (
          <CartCard
            key={product.product_id}
            {...product}
            getCart={getCart}
            product={{ ...product?.product }}
          />
        ))}
      </div>
      <div className="cart-con__checkout-con">
        <p>
          Subtotal ({cart.cart_count} items):{" "}
          <span>&#8358;{cart.cart_total.toLocaleString()}</span>
        </p>
        <button onClick={handleToCheckOut}>Proceed to checkout</button>
      </div>
    </div>
  );
}

export default Cart;
