import React, { FormEvent, useEffect, useState } from "react";
import { api } from "../../controllers/api";

function AddCategory() {
  const [categories, setCategories] = useState<{ category: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      let body = new FormData(e.target as HTMLFormElement);
      let req = await fetch(`${api}/consumable-category`, {
        method: "POST",
        body,
      });
      if (req.ok) {
        let res = await req.json();
        setCategories(res);
      }
    } catch (error) {
      alert("something went wrong, please contact Maroh, hehehe");
    }
    setLoading(false);
  };
  const getCategories = async () => {
    try {
      let req = await fetch(`${api}/consumable-categories`);
      if (req.ok) {
        let res = await req.json();
        setCategories(res);
      }
    } catch (e) {}
  };
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <div style={{ padding: "10px" }}>
      <form
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onSubmit={handleSubmit}
        action=""
      >
        <label style={{ display: "block" }} htmlFor="category">
          Consumable Category
        </label>
        <input
          placeholder="Enter name of category"
          type="text"
          name="category"
          id=""
          required
          style={{ padding: "5px 10px", margin: "10px", borderRadius: "5px" }}
        />
        <button
          style={{ padding: "5px 10px", margin: "10px", borderRadius: "5px" }}
          disabled={loading}
        >
          Add
        </button>
      </form>
      <h1>Available Consumable Categories</h1>
      {categories.map((c, i) => (
        <h3>
          {i + 1} {"       " + c.category}
        </h3>
      ))}
    </div>
  );
}

export default AddCategory;
