import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../..";
import { api } from "../../../controllers/api";
import { setCart } from "../../../controllers/reducers/cart";
import {
  startLoading,
  stopLoading,
} from "../../../controllers/reducers/loader";
import "../checkout.scss";
function PaymentStatus() {
  const { user, cart } = useSelector((state: RootState) => state);
  const [txStatus, setTxStatus] = useState({ loading: true, status: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const checkTxStatus = async () => {
    if (cart.cart_count === 0) {
      //   navigate("/");
      return;
    }
    let products = cart.cart.map((cart) => {
      return {
        quantity: cart.quantity,
        name: cart.product.name,
        image: cart.product.image,
        price: cart.price,
      };
    });
    //dispatch(startLoading());
    let ref = searchParams.get("trxref");
    try {
      let request = await fetch(api + "/confirm_payment/" + ref, {
        method: "POST",
        body: JSON.stringify({
          cart: cart.cart,
          orderNumber: `${user?.business_name[0].toUpperCase()}${user?.firstname[0].toUpperCase()}-${Date.now()}`,
          total: cart.cart_total,
        }),
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + user?.token,
        },
      });
      if (request.status === 200) {
        let res = await request.json();
        if (res.status) {
          setTxStatus({ loading: false, status: true });
          await fetch(api + "/empty_cart", {
            method: "DELETE",
            headers: { Authorization: "Bearer " + user?.token },
          });
          dispatch(setCart({ cart: [], cart_count: 0, cart_total: 0 }));
          setTxStatus({ loading: false, status: true });
          return;
        }
        setTxStatus({ loading: false, status: false });
      }
      setTxStatus({ loading: false, status: false });
    } catch (error) {
      console.log(error);
      setTxStatus({ loading: false, status: false });
    }
    // dispatch(stopLoading());
    setTxStatus({ loading: false, status: false });
  };
  // const postOrder = async () => {
  //   try {
  //     let req = await fetch(api + "/order", {
  //       body: JSON.stringify({
  //         orderNumber: `${user?.business_name[0].toUpperCase()}${user?.firstname[0].toUpperCase()}-${Date.now()}`,
  //         products,
  //         total: cart.cart_total,
  //         customerId: user?._id,
  //       }),
  //       method: "POST",
  //       headers: {
  //         Authorization: "Bearer " + user?.token,
  //         "Content-type": "application/json",
  //       },
  //     });
  //     if (req.status === 200) {
  //       await fetch(api + "/empty_cart", {
  //         method: "DELETE",
  //         headers: { Authorization: "Bearer " + user?.token },
  //       });
  //       dispatch(setCart({ cart: [], cart_count: 0, cart_total: 0 }));
  //     }
  //   } catch (error) {
  //     alert("Order posting failed");
  //     console.log(error);
  //   }
  //   dispatch(stopLoading());
  // };
  useEffect(() => {
    checkTxStatus();
  }, []);
  return (
    <div
      className={
        "payment-status-con " + (txStatus.status ? "success" : "failure")
      }
    >
      {txStatus.loading ? (
        <>Verifying Payment status...</>
      ) : txStatus.status ? (
        <div className="payment-status-con__success-con">
          <h3>Payment successful!</h3>
          <div className="payment-status-con__success-con__options-con">
            <button onClick={() => navigate("/", { replace: true })}>
              Continue Shopping
            </button>
            <button
              onClick={() => navigate("/account/orders", { replace: true })}
            >
              View Orders
            </button>
          </div>
        </div>
      ) : (
        <div className="payment-status-con__failure-con">
          <h3>Payment Failed</h3>
          <div className="payment-status-con__success-con__options-con">
            <button onClick={() => navigate("/cart")}>Go to Cart</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentStatus;
