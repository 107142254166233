import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../api";
export enum Status {
  idle,
  loading,
  succeeded,
  failed,
}

interface InitialState {
  products: {
    products: { title: string; _id: string; items: any[] }[];
    brands: { name: string; abbrv: string; image: string }[];
  };

  status: Number;
  error: string | undefined;
}
const initialState: InitialState = {
  products: { products: [], brands: [] },

  status: Status.idle,
  error: undefined,
};
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const response = await (await fetch(api + "/landing_products")).json();

    console.log(response);

    return response;
  }
);
const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    postAdded: (state, action) => {
      //  state.products.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.status = Status.loading;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = Status.succeeded;
        // Add any fetched posts to the array
        state.products = { ...action.payload };
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = Status.failed;
        state.error = action.error.message;
      });
  },
});

export const { postAdded } = productsSlice.actions;
export default productsSlice.reducer;
