import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../resources/images/search.svg";
import { ReactComponent as PersonIcon } from "../../../resources/images/person.svg";
import { ReactComponent as SavedIcon } from "../../../resources/images/heart_empty.svg";
import { ReactComponent as CartIcon } from "../../../resources/images/cart.svg";
import { RootState } from "../../../index";
import orderIcon from "../../../resources/images/order.png";
import { useSelector } from "react-redux";

interface Props {
  menu: { showMenu: Boolean; handleShowMenu: () => void };
  profileMenu: {
    showMenu: Boolean;
    handleMenu: (e: React.SyntheticEvent) => void;
  };
}
function NavBar({ menu, profileMenu }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, cart } = useSelector((state: RootState) => state);
  const [value, setValue] = useState("");

  let formRef = useRef<HTMLFormElement>(null);
  const handleToCart = () => {
    if (user) navigate("/cart");
    else navigate("/auth/login", { state: "/cart" });
  };
  const handleSearch = async (e: FormEvent) => {
    e.preventDefault();

    if (value!!.length > 0) {
      navigate("/all?query=" + value);
    }
  };

  function handleChange(e: ChangeEvent) {
    let value = (e.target as HTMLInputElement).value.trim();
    setValue(value);
  }
  function handleClick(e: React.SyntheticEvent, path: String) {
    navigate("/account/" + path);
    profileMenu.handleMenu(e);
  }
  return (
    <div className="nav-bar">
      <div className="nav-bar__one">
        <div
          onClick={menu.handleShowMenu}
          className={"hamburger " + (menu.showMenu ? "show-menu" : "")}
        >
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"> </div>
        </div>
        <Link className="logo" to="/">
          EasyMeds.ng
        </Link>
        <form
          ref={formRef}
          onSubmit={handleSearch}
          className="nav-bar__one__search-bar"
        >
          <input
            value={value}
            onChange={handleChange}
            type="search"
            name="query"
            id=""
          />
          <button className="img-con">
            <SearchIcon />
          </button>
        </form>
        <div className="nav-bar__one__account-con">
          <PersonIcon onClick={profileMenu.handleMenu} />

          {profileMenu.showMenu && (
            <div className="nav-bar__one__account-con__account-options">
              <div
                onClick={(e) => handleClick(e, "profile")}
                className="nav-bar__one__account-con__account-options__option"
              >
                <PersonIcon /> <p>My Account</p>
              </div>
              <div
                onClick={(e) => handleClick(e, "orders")}
                className="nav-bar__one__account-con__account-options__option"
              >
                <img src={orderIcon} alt="" /> <p>My Orders</p>
              </div>
              <div
                onClick={(e) => handleClick(e, "wishlist")}
                className="nav-bar__one__account-con__account-options__option saved"
              >
                <SavedIcon /> <p>My Wishlist</p>
              </div>
              <p
                onClick={() => {
                  navigate("/auth/login");
                }}
                className="logout"
              >
                LOGOUT
              </p>
            </div>
          )}
        </div>
        {user?.is_verified && (
          <div onClick={handleToCart} className="nav-bar__one__cart-con">
            <p className="cart-count">{cart.cart_count}</p>
            <CartIcon />
          </div>
        )}
      </div>
      <div className="nav-bar__two">
        <form
          onSubmit={handleSearch}
          ref={formRef}
          className="nav-bar__two__search-bar"
        >
          <input
            value={value}
            onChange={handleChange}
            type="search"
            name="query"
            id=""
          />
          <div role={"button"} className="img-con">
            <SearchIcon />
          </div>
        </form>
      </div>
    </div>
  );
}

export default NavBar;
