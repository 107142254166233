import React, { useState } from "react";
import loader from "../../../resources/images/minus.svg";
import { api } from "../../../controllers/api";
import { useNavigate } from "react-router-dom";
import Image from "./Image";
interface Props {
  brand_name: string;
  images: string[];
  price: number;
  strength: string;
  _id: string;
  isVerified?: boolean;
}
function HomeCard(props: Props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  const handleToDescription = (id: string) => {
    return () => navigate(`/description/${id}`);
  };
  return (
    <div onClick={handleToDescription(props._id)} className="home-card">
      <div className="home-card__image-con">
        <Image images={props.images} brand_name={props.brand_name} />
      </div>

      <div className="home-card__details">
        <p className="name">
          {props.brand_name + " " + (props.strength ?? "")}
        </p>

        <small className="price">
          &#8358;{props.isVerified ? props?.price?.toLocaleString() : "0.00"}
        </small>
      </div>
    </div>
  );
}

export default HomeCard;
