import React, { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { api } from "../../../controllers/api";
import { setUser } from "../../../controllers/reducers/user";

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    let form = e.target as HTMLFormElement;
    let body = new FormData(form);
    let elements = form.elements;
    let type = elements[2] as HTMLSelectElement;
    let url = "/customer";

    try {
      let req = await fetch(api + url, { method: "POST", body });
      if (req.ok) {
        let res = await req.json();
        dispatch(setUser(res));
        if (location.state) navigate(`${location.state}`);
        else navigate("/");
      }
    } catch (error: ErrorCallback | any) {
      alert(error.message);
    }
  };
  return (
    <div className="login-con">
      <h2>Create Account</h2>
      <form onSubmit={handleSubmit}>
        <div className="login-con__input-con">
          <label>First Name</label>
          <input required type="text" name="firstname" />
        </div>
        <div className="login-con__input-con">
          <label>Last Name</label>
          <input required type="text" name="lastname" />
        </div>
        {/* <div className="login-con__input-con">
          <label>Account type</label>
          <select required name="type" id="">
            <option selected={true} value="buyer">
              Buyer
            </option>
          </select>
        </div> */}
        {/* <div className="login-con__input-con">
          <label>Date of birth</label>
          <input required type="date" name="dob" />
        </div> */}
        {/* <div className="login-con__input-con">
          <label>Sex</label>
          <select required name="sex">
            <option disabled value="">
              Select sex
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="stupid">Something else</option>
          </select>
        </div> */}
        <div className="login-con__input-con">
          <label>Company name</label>
          <input required type="text" name="business_name" />
        </div>
        <div className="login-con__input-con">
          <label>Phone number</label>
          <input required inputMode="tel" name="phone_number" />
        </div>
        <div className="login-con__input-con">
          <label>Email address</label>
          <input required inputMode="email" name="email" />
        </div>
        <div className="login-con__input-con">
          <label>Password</label>
          <input required type="password" name="password" />
        </div>
        <div className="login-con__input-con">
          <label>Address</label>
          <input required type="text" name="address" />
        </div>
        <div className="login-con__input-con">
          <label>Registration number</label>
          <input
            required
            placeholder="PCN Registration number"
            type="text"
            name="reg_number"
          />
        </div>

        <button type="submit">Create Account</button>
      </form>
      <small>
        Already have an account? Login <Link to={"/auth/login"}>here</Link>
      </small>
    </div>
  );
}

export default Signup;
