import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  useNavigate,
} from "react-router-dom";
import Authorization from "./view/authorization/Authorization";
import "./resources/styles/index.scss";
import MerchantPage from "./view/home/components/MerchantPage";
import Home from "./view/home/Home";
import Login from "./view/authorization/components/Login";
import Signup from "./view/authorization/components/Signup";
import LandingPage from "./view/home/components/LandingPage";
import CategoryPage from "./view/home/components/CategoryPage";
import Description from "./view/description/Description";
import Cart from "./view/cart/Cart";
import { RootState } from ".";
import CheckOut from "./view/checkout";
import PaymentStatus from "./view/checkout/components/PaymentStatus";
import Account from "./view/account/Account";
import Profile from "./view/account/components/Profile";
import Orders from "./view/account/components/Orders";
import Wishlist from "./view/account/components/Wishlist";
import OrderDetail from "./view/account/components/OrderDetail";
import AddCategory from "./view/testground/AddCategory";
import AddressBook from "./view/account/components/AddressBook";
import { api } from "./controllers/api";
import { setUser } from "./controllers/reducers/user";

function App() {
  const { loader, user } = useSelector((state: RootState) => state);

  return (
    <Router>
      <div className="App">
        {loader && (
          <div className="loader-con">
            <div className="loader"></div>
          </div>
        )}

        <Switch>
          <Route path="/auth" element={<Authorization />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" />
            <Route
              index
              element={
                <main style={{ padding: "1rem" }}>
                  <p>404</p>
                </main>
              }
            />
          </Route>
          <Route path="/" element={<Home />}>
            <Route path="" element={<LandingPage />} />
            <Route path="all" element={<CategoryPage />} />
            <Route path="merchant/:merchantId" element={<MerchantPage />} />
            <Route path="description/:id" element={<Description />} />
            <Route path="cart" element={<Cart />} />
            <Route path="account" element={<Account />}>
              {" "}
              <Route path="profile" element={<Profile />} />
              <Route path="address_book" element={<AddressBook />} />
              <Route path="orders" element={<Orders />} />
              <Route path="orders/:id" element={<OrderDetail />} />
              <Route path="wishlist" element={<Wishlist />} />
            </Route>
          </Route>
          <Route path="checkout" element={<CheckOut />}></Route>
          <Route path="category" element={<AddCategory />}></Route>

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>404</p>
              </main>
            }
          />
          <Route path="txstatus" element={<PaymentStatus />} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
