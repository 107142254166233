import React from "react";
import { ReactComponent as Logo } from "../../../resources/images/chair.svg";
import { ReactComponent as DrugIcon } from "../../../resources/images/drug.svg";
import { ReactComponent as SyringeIcon } from "../../../resources/images/syringe.svg";
import { ReactComponent as StethIcon } from "../../../resources/images/stethoscope.svg";
import { ReactComponent as MicroIcon } from "../../../resources/images/microscope.svg";
import { ReactComponent as PersonIcon } from "../../../resources/images/person.svg";
import { useNavigate } from "react-router-dom";
interface Props {
  handleMenu: {
    showMenu: boolean;
    handleCloseMenu: () => void;
  };
}
function SideMenu({ handleMenu }: Props) {
  const handleNavigation = (path: string) => {
    navigate("/all?type=" + path);
    handleMenu.handleCloseMenu();
  };
  const navigate = useNavigate();
  return (
    <div className={"side-menu " + (handleMenu.showMenu ? "slide-in" : "")}>
      <div
        onClick={(e) => handleNavigation("Drug")}
        className="side-menu__menu-item"
      >
        <DrugIcon /> <p>Drugs</p>
      </div>
      <div
        onClick={(e) => handleNavigation("Medical Equipment")}
        className="side-menu__menu-item"
      >
        <StethIcon /> <p>Equipments</p>
      </div>
      <div
        onClick={(e) => handleNavigation("Medical Device")}
        className="side-menu__menu-item"
      >
        <MicroIcon /> <p>Devices</p>
      </div>
      <div
        onClick={(e) => handleNavigation("Consumable")}
        className="side-menu__menu-item"
      >
        <SyringeIcon />
        <p>Consumables</p>
      </div>
    </div>
  );
}

export default SideMenu;
