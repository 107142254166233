import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { api } from "../../../controllers/api";
import { IProduct } from "../../../controllers/reducers/cart";
import ProductCard from "./ProductCard";

function MerchantPage() {
  let { merchantId } = useParams();
  let [merchant, setMerchant] = useState<{
    business_name: string;
    products: IProduct[];
  }>();
  // let [searchParams, setSearchParams] = useSearchParams({ name: "lolo" });

  const handleGetMerchant = async () => {
    try {
      let req = await fetch(api + "/merchant/" + merchantId);
      if (req.ok) {
        let merchant = await req.json();
        console.log(merchant.products[0]);
        setMerchant(merchant);
      }
    } catch (error) {
      alert("Merchant not found");
    }
  };
  useEffect(() => {
    handleGetMerchant();
  }, []);
  return (
    <div className="merchant-page-con">
      <h3>{merchant?.business_name}</h3>
      <div className="merchant-page-con__products-con">
        {merchant?.products?.map((product) => (
          <ProductCard
            id={product._id}
            key={product._id}
            {...product}
            image={product.displayImage}
          />
        ))}
      </div>
    </div>
  );
}

export default MerchantPage;
