import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../index";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../../controllers/api";
import Image from "./Image";
import { addToCart, setCart } from "../../../controllers/reducers/cart";
import {
  startLoading,
  stopLoading,
} from "../../../controllers/reducers/loader";

interface Props {
  name: string;
  image?: string;
  price?: Number;
  expiry_date: string;
  strength: string;
  id: string;
  company?: string;
  merchant_id: string;
}
function ProductCard({
  id,
  name,
  price,
  image,
  expiry_date,
  merchant_id,
  strength,
  company,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const { user, cart } = useSelector((state: RootState) => state);
  let token = user?.token;
  const checkCart = (id: string): boolean => {
    let exist = false;
    for (let x = 0; x < cart.cart.length; x++) {
      if (cart.cart[x].product._id === id) {
        exist = true;
        break;
      }
    }
    return exist;
  };
  const [inCart, setInCart] = useState(checkCart(id));
  const handleCart = async () => {
    if (!user) {
      navigate("/auth/login", { state: location.pathname });
      return;
    } else if (!checkCart(id)) {
      dispatch(startLoading());

      try {
        let req = await fetch(`${api + "/cart"}`, {
          body: JSON.stringify({
            product: {
              _id: id,
              name,
              price,
              image,
              expiry_date,
              merchant_id,
              company,
            },
            product_id: id,
            price,
            quantity: 1,
            date_added: new Date().toISOString(),
          }),
          method: "POST",
          headers: {
            AUthorization: "Bearer " + token,
            "Content-type": "application/json",
          },
        });
        if (req.ok) {
          let newCart = await req.json();
          setInCart(true);
          dispatch(addToCart(newCart));
        }
        dispatch(stopLoading());
      } catch (error) {
        alert("something went wrong");
        dispatch(stopLoading());
      }
    }
  };
  const handleDeleteFromCart = (id: string, token: string) => {
    return async () => {
      dispatch(startLoading());
      try {
        let req = await fetch(`${api}/cart/${id}`, {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        });
        if (req.ok) {
          let res = await req.json();
          dispatch(setCart(res));
          dispatch(stopLoading());
        }
        setInCart(false);
      } catch (error) {
        console.log(error);
        dispatch(stopLoading());
      }
    };
  };
  const handleToDescription = (id: string) => {
    navigate("/description/" + id);
  };
  return (
    <div className="product-card">
      <div
        onClick={() => handleToDescription(id)}
        className="product-card__img-con"
      >
        <Image images={[image!!]} brand_name={name} />
      </div>
      <div className="product-card__details-con">
        <p className="product-card__details-con__name">
          {name + " " + (strength ?? "")}
        </p>
        <small>{company}</small>
        <p className="product-card__details-con__price">
          &#8358;{user?.is_verified ? price?.toLocaleString() : "0.00"}
        </p>
        {user?.is_verified ? (
          inCart ? (
            <button
              onClick={handleDeleteFromCart(id, user!!.token)}
              className="product-card__details-con__button product-card__details-con__button--remove "
            >
              Remove from Cart
            </button>
          ) : (
            <button
              onClick={handleCart}
              className="product-card__details-con__button"
            >
              Add to Cart
            </button>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
