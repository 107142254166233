import { createSlice } from "@reduxjs/toolkit";
let storedUser = localStorage.getItem("user");

let initialState: {
  _id: string;
  lastname: string;
  firstname: string;
  fullname: string;
  sex: string;
  phone_number: string;
  reg_number: string;
  default_address: {
    state: string;
    street: string;
    lga: string;
    landmarks: string;
  };
  addresses: {
    _id: string;
    state: string;
    street: string;
    lga: string;
    landmarks: string;
    is_default: boolean;
  }[];
  type: string;
  password: string;
  is_email_verified: boolean;
  is_phone_verified: boolean;
  is_verified: boolean;
  date_of_birth: string;
  email: string;
  token: string;
  business_name: string;
} | null;

initialState = storedUser ? JSON.parse(storedUser) : null;

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      localStorage.setItem("user", JSON.stringify(payload));
      return payload;
    },
    clearUser: (state, { payload }) => {
      localStorage.removeItem("user");
      return null;
    },
  },
});
export const { clearUser, setUser } = slice.actions;
export default slice.reducer;
