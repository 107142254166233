import { createSlice } from "@reduxjs/toolkit";
export interface IState {
  cart_count: number;
  cart_total: number;
  cart: {
    product_id: string;
    quantity: number;
    date_added: string;
    price: number;
    product: IProduct;
  }[];
}
export interface IMerchant {
  name: String;
  _id: String;
  email: String;
}
export interface IProduct {
  _id: string;
  images: string[];
  image: string;
  name: string;
  price: number;
  company: string;
  merchant: IMerchant;
  expiry_date: string;
  strength: string;
  merchant_id: string;
  displayImage: string;
}
let rawState = localStorage.getItem("cart");

const updateTotal = (
  arr: {
    product_id: string;
    quantity: number;
    date_added: string;
    product: IProduct;
  }[]
): number => {
  let total = 0;
  arr.forEach((product) => {
    total += product.quantity * product.product.price;
  });
  return total;
};

const initialState: IState = rawState
  ? JSON.parse(rawState)
  : { cart: [], cart_count: 0, cart_total: 0 };
const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, { payload }) => {
      state = payload;
      state.cart_total = updateTotal(state.cart);
      localStorage.setItem("cart", JSON.stringify(payload));
      return state;
    },
    addToCart: (state, { payload }) => {
      state = payload;
      state.cart_total = updateTotal(state.cart);
      localStorage.setItem("cart", JSON.stringify(payload));
      return state;
    },
    updateCart: (state, { payload }) => {
      let index = state.cart.findIndex(
        (product) => product.product._id === payload.product_id
      );
      state.cart[index] = payload;
      state.cart_total = updateTotal(state.cart);
      localStorage.setItem("cart", JSON.stringify(state));
      return state;
    },
    deleteFromCart: (state, { payload }) => {
      let newCart = state.cart.filter(
        (product) => product.product_id !== payload.id
      );
      state.cart = newCart;
      state.cart_count = newCart.length;
      state.cart_total = updateTotal(state.cart);
      return state;
    },
  },
});

export const { setCart, addToCart, updateCart, deleteFromCart } = slice.actions;
export default slice.reducer;
