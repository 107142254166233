import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  initialState: false,
  name: "loader",
  reducers: {
    startLoading: () => true,
    stopLoading: () => false,
  },
});

export const { startLoading, stopLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
