import React, { ChangeEvent, useState } from "react";
import DemoImage from "../../../resources/images/demo-image.jpg";
import { ReactComponent as TrashIcon } from "../../../resources/images/trash.svg";
import { ReactComponent as PlusIcon } from "../../../resources/images/plus.svg";
import { ReactComponent as MinusIcon } from "../../../resources/images/minus.svg";
import { api } from "../../../controllers/api";
import Image from "../../home/components/Image";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../controllers/reducers/loader";
import {
  IMerchant,
  IProduct,
  setCart,
  updateCart,
} from "../../../controllers/reducers/cart";
import { RootState } from "../../..";
import { useNavigate } from "react-router-dom";
enum option {
  plus,
  minus,
  neutral,
}
interface IProps {
  product: IProduct;
  quantity: number;
  date_added: string;
  product_id: string;
  getCart: () => void;
}
let timer = 0;
function CartCard({
  product,
  quantity: q,
  date_added,
  product_id,
  getCart,
}: IProps) {
  const [quantity, setQuantity] = useState(q);
  const { user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleQuantity = (arrg: Number) => {
    return async (e: ChangeEvent<HTMLInputElement> | any) => {
      clearTimeout(timer);
      let newQuantity = quantity;
      switch (arrg) {
        case option.plus:
          newQuantity++;
          //  setQuantity((state) => state + 1 ?? 0);
          break;
        case option.minus:
          if (quantity > 0) {
            newQuantity--;
          } else handleDeleteFromCart();
          break;
        default:
          newQuantity = parseInt(
            e.target.value === "" ? 0 : e.target.value,
            10
          );
          e.target.value = "";
          //setQuantity(newQuantity - 0);
          break;
      }
      setQuantity(newQuantity);
      let cartProduct = {
        product_id,
        product,
        quantity: newQuantity,
      };
      timer = window.setTimeout(() => {
        handleUpdateCart(cartProduct, newQuantity);
      }, 500);
    };
  };
  const handleUpdateCart = async (cartProduct: any, newQuantity: number) => {
    dispatch(startLoading());
    try {
      let req = await fetch(api + "/cart", {
        method: "PATCH",
        body: JSON.stringify(cartProduct),
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "Content-type": "application/json",
        },
      });
      if (req.ok) {
        setQuantity(newQuantity);
        dispatch(updateCart(cartProduct));
      }
      dispatch(stopLoading());
    } catch (error) {
      console.log(error);
      alert("Update of product failed");
      dispatch(stopLoading());
    }
    dispatch(stopLoading());
  };
  const handleDeleteFromCart = async () => {
    dispatch(startLoading());
    try {
      let req = await fetch(`${api}/cart/${product_id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${user!!.token}` },
      });
      if (req.ok) {
        let res = await req.json();
        getCart();
        dispatch(stopLoading());
      }
      dispatch(stopLoading());
    } catch (error) {
      console.log(error);
      dispatch(stopLoading());
    }
  };

  const handleClick = (id: string) => {
    return () => navigate(`/description/${id}`);
  };

  return !product ? (
    <>...</>
  ) : (
    <div className="cart-con__cart-cards__card">
      <div className="cart-con__cart-cards__card__product-details">
        <div
          onClick={handleClick(product_id)}
          className="cart-con__cart-cards__card__product-details__image-con"
        >
          <Image brand_name={product.name} images={[product.image] ?? []} />
        </div>
        <div className="cart-con__cart-cards__card__product-details__meta">
          <h4>{product.name}</h4>
          <p className="company">
            Manufacturer: <span>{product.company}</span>
          </p>
          <p className="merchant">
            Merchant: <span>{product.merchant.name}</span>
          </p>
          <p className="price">&#8358;{product.price}</p>
          <div className="cart-con__cart-cards__card__product-details__meta__options">
            <div onClick={handleDeleteFromCart} className="delete-con">
              <TrashIcon /> <small>Remove</small>
            </div>
            <div className="update-con">
              <MinusIcon onClick={handleQuantity(option.minus)} />{" "}
              <input
                onChange={handleQuantity(option.neutral)}
                value={quantity}
                type="number"
                name=""
                id=""
              />
              <PlusIcon onClick={handleQuantity(option.plus)} />
            </div>
          </div>
        </div>
        <div className="cart-con__cart-cards__card__product-details__price-box">
          {" "}
          <p>
            Total: <span>&#8358;{product.price * quantity}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CartCard;
