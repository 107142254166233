import React from "react";
import { Carousel } from "react-responsive-carousel";
import HomeRow from "./HomeRow";
import SideMenu from "./SideMenu";
import icon1 from "../../../resources/images/slide1.jpg";
import icon2 from "../../../resources/images/slide2.jpg";
import icon3 from "../../../resources/images/slide3.jpg";
import icon4 from "../../../resources/images/slide4.jpg";
import { useShowMenu } from "../Home";
import { useSelector } from "react-redux";
import { RootState } from "../../../index";
import { Status } from "../../../controllers/reducers/products";
import { api } from "../../../controllers/api";
import { useNavigate } from "react-router-dom";
import Image from "./Image";

function LandingPage() {
  const { showMenu } = useShowMenu();
  const navigate = useNavigate();
  const { products: productObject, user } = useSelector(
    (state: RootState) => state
  );
  const { products, status } = productObject;
  const handleToBrand = (brand: string) => {
    return () => navigate("/all?brand=" + brand.toLowerCase());
  };
  return (
    <>
      <section className="intro-section">
        {/* <SideMenu showMenu={showMenu} /> */}
        {/* <div className="mobile-menu">
  <div className="side-menu"></div>
  <div className="filler"></div>
  </div> */}
        <div className="intro-con">
          <Carousel
            showThumbs={false}
            className="carousel-con"
            dynamicHeight={true}
            swipeable={true}
            emulateTouch={true}
            infiniteLoop={true}
            autoPlay={true}
          >
            <div>
              <img src={icon1} alt="" />
            </div>
            <div>
              <img src={icon2} alt="" />
            </div>
            <div>
              <img src={icon3} alt="" />
            </div>
            <div>
              <img src={icon4} alt="" />
            </div>
          </Carousel>
        </div>
      </section>
      <section className="content-section">
        <div className="content-section__class-con">
          <h3>Brands</h3>
          <div className="content-section__class-con__class-row">
            {products.brands.map((brand: any) => (
              <div
                onClick={handleToBrand(brand.name)}
                key={brand._id}
                className="logo-con"
              >
                <Image images={[brand.image]} brand_name={brand.name} />
              </div>
            ))}
          </div>
        </div>
        {status === Status.loading ? (
          <>Loading</>
        ) : (
          products.products.map(
            ({ title, items, _id }) =>
              items.length > 0 && (
                <HomeRow
                  key={_id}
                  title={title}
                  isVerified={user?.is_verified}
                  products={items}
                />
              )
          )
        )}
      </section>
    </>
  );
}

export default LandingPage;
