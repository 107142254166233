import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../..";

function Summary() {
  const { cart } = useSelector((state: RootState) => state);
  return (
    <div className="checkout-con__body__summary">
      <h3 className="summary-heading">Summary</h3>
      <div className="checkout-con__body__summary__table-con">
        {cart.cart.map((product, index) => (
          <div key={product.product_id} className="summary-row">
            <p className="summary-name">{product.product.name}</p>
            <p>x{product.quantity?.toLocaleString()}</p>
            <p>&#8358;{product.price?.toLocaleString()}</p>
            <p className="summary-row__total">
              &#8358;{(product.price * product.quantity)?.toLocaleString()}
            </p>
          </div>
        ))}
      </div>
      <p className="summary-total">
        <span>Total</span>
        <span> &#8358;{cart.cart_total}</span>
      </p>
    </div>
  );
}

export default Summary;
